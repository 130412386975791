import * as React from "react"

export default class contactForm extends React.Component {
//
  constructor(props){
    super(props);
    this.state = {
      submitted: false,
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
    }
this.handleInputChange = this.handleInputChange.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
    //console.log(this.state);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    //this.setState({submitted:true})

    console.log(this.state);
    if(this.state.name && this.state.email && this.state.message){
      document.getElementById('contactForm').submit();
    }
  }
  render (){ 
    const theForm = <form onSubmit={this.handleSubmit.bind(this)} id="contactForm" name="contactForm" className="" action="https://usebasin.com/f/d84be4920336" method="post">
        <div className="row">
          <div className="six columns">
            <label htmlFor="name">Name *</label>
            <input className="u-full-width" type="text" name="name" id="name" placeholder="Name" required value={this.state.name} onChange={this.handleInputChange} />
          </div>
          <div className="six columns">
          <label htmlFor="email">Email *</label>
            <input className="u-full-width" type="email" name="email" id="email" placeholder="Email" required value={this.state.email} onChange={this.handleInputChange} />
          </div>
        </div>
        <div className="row">
          <div className="six columns">
          <label htmlFor="phone">Phone</label>
            <input className="u-full-width" type="text" name="phone" id="phone" placeholder="Phone" value={this.state.phone} onChange={this.handleInputChange} />
          </div>
          <div className="six columns">
          <label htmlFor="phone">Company</label>
            <input className="u-full-width" type="text" name="company" id="company" placeholder="Company" value={this.state.company} onChange={this.handleInputChange} />
          </div>
        </div>
        <div className="row">
          <div className="twelve columns">
            <label htmlFor="phone">Message</label>
            <textarea className="u-full-width" name="message" id="message" rows="10" required onChange={this.handleInputChange} value={this.state.message}></textarea>
            
            <input type="submit" />
          </div>
        </div>
      </form>;

      const submitMessage = <p>Thanks!</p>

    return (
      <div className="form-container container">
        {(this.state.submitted)? submitMessage: theForm}
      </div>
)}
}
