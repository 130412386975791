import * as React from "react"
import Layout from "../component/layout.js"
import ProjectBox from "../component/projectBox.js"
import { graphql } from 'gatsby'
import heroImage from "../images/hero-image.jpg"
import resume from "../images/resume.pdf"
import ContactForm from "../component/form.js"
import { Helmet } from "react-helmet"
import favicon from "../images/icon.png"


const IndexPage = ({data}) => {
  let projects = data.allMarkdownRemark.nodes; 
  let large = projects.filter((v) => v.frontmatter.type === 'large');
  let medium = projects.filter((v) => v.frontmatter.type === 'medium');
  let small = projects.filter((v) => v.frontmatter.type === 'small');
  var sortedProjects = [];
  for (var i = 0; i < projects.length; i++) {

    //large spot
    if(i % 8 === 0 || i % 8 === 7) {
      if(large.length > 0){
        sortedProjects[i] = large.shift();
      } else if (medium.length > 0) {
        sortedProjects[i] = medium.shift();
      } else if (small.length > 0) {
        sortedProjects[i] = small.shift();
      }
    }
    //medium spot
    if(i % 8 === 1 || i % 8 === 4) {
      if (medium.length > 0) {
        sortedProjects[i] = medium.shift();
      } else if(large.length > 0){
        sortedProjects[i] = large.shift();
      } else if (small.length > 0) {
        sortedProjects[i] = small.shift();
      }
    }
    //small spot
    if(i % 8 === 2 ||
       i % 8 === 3 ||
       i % 8 === 5 ||
       i % 8 === 6) {
      if (small.length > 0) {
        sortedProjects[i] = small.shift();
      } else if (medium.length > 0) {
        sortedProjects[i] = medium.shift();
      } else if(large.length > 0){
        sortedProjects[i] = large.shift();
      }  
    }
  }

  return (
    <Layout>
    <Helmet>
      <title>Barton White - Portfolio</title>
      <link rel="icon" href={favicon} />">
    </Helmet>
    <section id="hero">
      <div className="container">
        <div className="six columns">
          <h1>Hi, my name is <br /><strong>Barton White</strong></h1>
          <p>I am a Web Developer from Portland, OR. I enjoy web development because it combines my love of design and creativity with my love of programming and technology. I have been making high quality websites in a variety of platforms for over six years. Below are the highlights of sites I have developed.</p>
        </div>
        <div className="six columns">
          <img src={heroImage} alt="" />
        </div>
      </div>
      
    </section>
    <section>
      <div className="container">
        <div className="three columns"><p>&nbsp;</p>
        </div>
        <div className="six columns">
          <p>I create websites with the user in mind from start to finish. I am well-versed in HTML, CSS, Javascript, PHP, Python, and Node. Many of my sites are Wordpress and I have created custom plugins and themes for that platform. The site you are looking at now was created with Gatsby and I am really excited about JAMstack and advanced static sites.</p>
          <p>Click on my projects below to read more about my role in each of them. More images are coming soon.</p>
            <a href={resume} target="_blank" rel="noreferrer" download className="button button-primary">View my resume</a>
        </div>
      </div>
    </section>
    <section id="projects">
      <div className="grid">
      { sortedProjects.map((v,i) => {
        return (<ProjectBox>{v}</ProjectBox>)
      })}
      </div>
    </section>
  </Layout>
  )
}

export default IndexPage


export const query = graphql`query MyQuery {
  allMarkdownRemark(sort: {fields: frontmatter___weight, order: DESC}) {
    nodes {
      frontmatter {
        title
        image {
          publicURL
        }
        link
        type
        color
        slug
      }
    }
  }
}`