import React from "react"
import { Link } from "gatsby"

export default function ProjectBox({children}) {
	return (
		<Link to={children.frontmatter.slug} className={"box " + children.frontmatter.type} style={{backgroundImage: (children.frontmatter.image) ? 
			'url('+children.frontmatter.image.publicURL+')':
			'none',
			backgroundColor: (children.frontmatter.color) ? children.frontmatter.color : '#666'}}>
          <h2>{children.frontmatter.title}</h2>
        </Link>
	)
}
